// Basic Styling

html {

  body {

    // Navigation

    #portal-mainnavigation {
      margin-bottom: 2em;
    }

    // Header

    #portal-top {}

    // Content

    #content {
      padding-top: 2em;
      padding-bottom: 2em;
    }

    // Footer

    #portal-footer {}

  }

}